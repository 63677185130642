export const ourFocus = {
  header: 'Our focus',
  content: [
    `Vancouver Island's premier real estate appraisal firm, Bridge & Associates Appraisals Ltd. offers a variety of services, including full, restricted, and oral appraisals of residential properties. Our goal is to provide appraisals with the highest degree of accuracy. Clear communication and customer satisfaction are the pillars of our business.`,
  ],
};

export const servicesWeProvideLink = {
  link: {
    beforeLink: `For more information about the types of properties we provide appraisals for, as well as some of the reasons you might need an appraisal, `,
    link: `click here`,
    href: '/services-we-provide',
    afterLink: `.`,
  },
};

export const whyChooseUs = {
  header: 'Why choose us?',
  content: [
    `The success of our business has been built on our approach to the careful collection and verification of market data. Our appraisers are experienced, knowledgeable and CNAREA-certified.`,
  ],
};

export const accurateResults = {
  header: 'Accurate results',
  content: [
    `Our experience in the appraisal field has shown us the need for precise and accurate data when conducting appraisals. We focus on clear, effective communication to ensure that we understand the specifics of your residential appraisal request.`,
  ],
};

export const whatWeOffer = {
  header: `What we offer`,
  content: [
    `We work for homeowners wishing to know the real market value of their homes or revenue properties. Our appraisers provide full, restricted, and oral appraisals of a variety of residential properties, which may include:`,
  ],
};

export const propertyTypes = {
  header: `Reasons for a home appraisal`,
  content: [
    `Mortgage financing, marital settlements, estate valuations, legal matters such as valuation disputes, and foreclosure/forced sale often require a home appraisal. Frequently, a forensic or “retrospective” appraisal is required to show the value of a home at a certain point in history. We also work for appraisal management companies, banks, credit unions, private lenders, mortgage brokers and all manner of financial institutions.`,
  ],
};

export const aNoteFromOurFounder = {
  header: `A note from our founder`,
  content: [
    `“Our extensive appraisal territory generally extends from Nanaimo to Port Hardy and every community in between. We travel westward to Port Alberni, Ucluelet and Tofino. Living in the area for as long as I have (over 25 years) affords me the expertise of truly knowing the market in the Central Vancouver Island region.”`,
    `-Martin Bridge, DAR`,
  ],
};

export const whereWeWork = {
  header: `Where we work`,
  content: [
    `We service the central and northern communities of beautiful Vancouver Island, including:`,
  ],
};

export const contactUs = {
  header: `Contact us`,
  content: [
    `Please don’t hesitate to contact us for a quote or for more information. We are happy to answer your questions.`,
  ],
};

export const connectWithUs = {
  header: `Connect with us`,
  content: [
    `If you would like to connect with us on social media, you can find us on the following networks:`,
  ],
};

export const leaveUsAReview = {
  header: `Leave us a review`,
  content: [
    `If you would like to leave feedback about your experience with Bridge & Associates Appraisals Ltd., you can find our Google Business listing at the following link:`,
  ],
};

export const notFound = {
  header: `The page you were looking for does not exist`,
  content: [`Here are some helpful links instead:`],
};
