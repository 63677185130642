import { Link } from 'gatsby';
import React from 'react';

import * as indexContentStyles from './content.module.css';

type Content = {
  header: string;
  content: string[];
};

type ContentProps = {
  children?: React.ReactNode;
  withPaddingTop?: boolean;
  withPaddingBottom?: boolean;
  content: Content;
  opaque?: boolean;
  anchor?: string;
};

type GetContentStylesProps = {
  i: number;
  withPaddingBottom?: boolean;
  numberOfParagraphs: number;
  opaque?: boolean;
};

const getContentStyles = ({
  i,
  withPaddingBottom,
  numberOfParagraphs,
  opaque,
}: GetContentStylesProps) =>
  `${indexContentStyles.content} ${
    withPaddingBottom && i === numberOfParagraphs - 1
      ? indexContentStyles.extraPaddingBottom
      : ''
  } ${opaque ? indexContentStyles.opaque : ''}`;

type ContentWithLinkProps = {
  content: string;
  link: string;
  linkText: string;
  withPaddingBottom?: boolean;
};

export const ContentWithLink = ({
  content,
  link,
  linkText,
  withPaddingBottom,
}: ContentWithLinkProps) => {
  const className = `${indexContentStyles.contentWithLink} ${
    withPaddingBottom ? indexContentStyles.extraPaddingBottom : ''
  }`;
  return (
    <p className={className}>
      {content}{' '}
      <Link className={indexContentStyles.contentLink} to={link}>
        {linkText}
      </Link>
      .
    </p>
  );
};

export const Content = ({
  content,
  children,
  withPaddingTop,
  withPaddingBottom,
  opaque,
  anchor,
}: ContentProps) => {
  const numberOfParagraphs = content.content.length;

  const headerStyles = `${indexContentStyles.heading} ${
    withPaddingTop ? indexContentStyles.extraPaddingTop : ''
  } ${opaque ? indexContentStyles.opaque : ''}`;
  const siblingStyles = `${indexContentStyles.sibling} ${
    opaque ? indexContentStyles.opaque : ''
  }`;

  return (
    <>
      <h2 className={headerStyles} id={anchor}>
        {content.header}
      </h2>
      {content.content.map((content, i) => (
        <p
          key={`content-${i}`}
          className={getContentStyles({
            i,
            withPaddingBottom,
            numberOfParagraphs,
            opaque,
          })}
        >
          {content}
        </p>
      ))}
      {children && <section className={siblingStyles}>{children}</section>}
    </>
  );
};
