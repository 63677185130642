import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { Content, ContentWithLink } from '../components/content';
import { Fold } from '../components/fold';
import { Hero } from '../components/hero';
import * as imageStyles from '../components/image.module.css';
import { Layout } from '../components/layout';
import { accurateResults, ourFocus, whyChooseUs } from '../content';

const IndexPage = () => (
  <Layout
    title="Bridge & Associates Appraisals Ltd.: Home appraisals you can trust"
    description="Bridge & Associates Appraisals Ltd. provides residential real estate appraisal and consulting services to clients from the private and public sector."
  >
    {({ setIsMenuOpen }) => (
      <>
        <Hero
          setIsMenuOpen={setIsMenuOpen}
          slogan="Home appraisals you can trust"
          image={
            <StaticImage
              alt="A large, grey home seen from the street"
              loading="eager"
              layout="fullWidth"
              imgClassName={imageStyles.background}
              className={imageStyles.background}
              src="../images/house.jpg"
            />
          }
        >
          <Content content={ourFocus} withPaddingTop>
            <ContentWithLink
              withPaddingBottom
              link="/services/"
              linkText="refer to our complete list of services"
              content={`For more information about the types of properties we provide
            appraisals for, as well as some of the reasons you might need an
            appraisal,`}
            />
            <StaticImage
              alt="A modern interior decorated with beautiful furniture"
              className={imageStyles.inlineImage}
              aspectRatio={16 / 9}
              src="../images/indoor.jpg"
            />
          </Content>
        </Hero>
        <Fold>
          <Content content={whyChooseUs} opaque withPaddingTop>
            <ContentWithLink
              link="/services/#where-we-work"
              linkText="refer to the list of areas we service"
              content={`We serve communities in the Central, Northern and Pacific Rim
            regions of Vancouver Island, including Nanaimo, Campbell River,
            Parskville and Courtney-Comox. For more information,`}
            />
          </Content>
          <Content content={accurateResults} opaque>
            <ContentWithLink
              withPaddingBottom
              link="/contact/"
              linkText="refer to our contact information"
              content={`To speak to one of our qualified appraisers about how we can help with your home appraisal needs,`}
            />
          </Content>
        </Fold>
      </>
    )}
  </Layout>
);

export default IndexPage;
